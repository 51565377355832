import i18n from '@/plugins/w/translate'
import { LocaleMessage } from 'vue-i18n'

export default {
    parseToString(data) {
        if (typeof data == 'string') {
            return i18n.t(data)

        } else if (data && data.error && typeof data.error == 'string') {
            return i18n.t(data.error)

        } else if (data && data.error && typeof data.error == 'object') {
            return this._parseErrorObj(data.error)

        } else if (data.data && data.data.error && typeof data.data.error == 'string') {
            return i18n.t(data.data.error)

        } else if (data && data.data && data.data.error && typeof data.data.error == 'object') {
            return this._parseErrorObj(data.data.error)

        } else if (data.statusText) {
            return i18n.t(data.statusText)

        } else {
            return i18n.t(data)
        }
    },
    _parseErrorObj(error) {
        const out: Array<LocaleMessage> = []

        Object.values(error).forEach(m => {
            out.push(i18n.t(m))
        })

        return out.join('<br>')
    }
}