import axios from 'axios'
import store from '@/plugins/app/_config/store'

let isRefreshing = false
let refreshHook = Promise.resolve(null)

async function _unauthorised(error) {
    try {
        if(!error.response || error.response.status != 401)
            throw error

        if(!isRefreshing) {
            refreshHook = store.dispatch('wAuth/refreshToken').finally(() => isRefreshing = false)
            isRefreshing = true
        }

        const token = await refreshHook
        if (!token) {
			store.dispatch('wAuth/logout')
            throw 'Token not refreshed!'
        }
        
        error.config.headers = {
            'Authorization': `bearer ${token}`
        }

        return await axios.request(error.config)
    } catch(err) {
        return Promise.reject(error)
    }
}

axios.interceptors.response.use(
	(response) => response,
    (error) => _unauthorised(error)
)
