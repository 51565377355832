import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import VueCompositionAPI, { ref } from '@vue/composition-api'
import utils from './utils'
import './css/toast.css'

Vue.use(VueToast)
Vue.use(VueCompositionAPI)

export default {
    _isLoading: ref(false),

    isLoading() {
        return this._isLoading.value
    },

    stopLoading() {
        this._isLoading.value = false
    },

    error(error='Error') {
        this._isLoading.value = false

        Vue.$toast.open({
            message: utils.parseToString(error),
            type: 'error',
            position: 'top',
            duration: 1000000,
        })
    },

    success(message='Success') {
        this._isLoading.value = false

        Vue.$toast.open({
            message: message,
            type: 'success',
            position: 'top',
            duration: 1000000
        })
    },

    clear_loading() {
        this._isLoading.value = true
        Vue.$toast.clear()
    },
    
    clear() {
        Vue.$toast.clear()
    }
}
