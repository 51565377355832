import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import loginGuardIfMetaSet from '@/plugins/w/authStore/loginGuardIfMetaSet'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/plugins/app/home/home.vue')
    },
    {
        path: '/dashboard/:id',
        name: 'Dashboard',
        component: () => import('@/plugins/app/dashboard/dashboard.vue')
    },
    {
        path: '/aktivovat-ucet/:code/:email',
        name: 'ActivateAccount',
        component: () => import('@/plugins/app/activate-account/activate-account.vue')
    },
    //Authorized
    {
        path: '/zoznam/',
        name: 'Lists',
        meta: { requiresAuth: true },
        component: () => import('@/plugins/app/_layout/list.vue'),
        children: [
            {
                path: '',
                redirect: { name: 'Tests' }
            },
            {
                path: 'testy',
                name: 'Tests',
                component: () => import('@/plugins/app@exams/exams/exams.vue'),
            },
            {
                path: 'testy/pridat/:hash',
                name: 'AddSharedExamTemplate',
                component: () => import('@/plugins/app@exams/add-shared-exam/add-shared-exam.vue')
            },
            {
                path: 'skupiny',
                name: 'StudentGroups',
                component: () => import('@/plugins/app@groups/student-groups/student-groups.vue')
            },
            {
                path: 'skupiny/pridat/:hash',
                name: 'AddSharedStudentGroup',
                component: () => import('@/plugins/app@groups/add-student-group/add-student-group.vue')
            }
        ]
    },
    {
        path: '/test/:id?',
        name: 'ExamDetail',
        meta: { requiresAuth: true },
        component: () => import('@/plugins/app/_layout/exam.vue'),
        children: [
            {
                path: '',
                name: 'Exam',
                component: () => import('@/plugins/app@exams/exam-detail/exam-detail.vue'),
            },
            {
                path: 'vysledky',
                name: 'ExamResults',
                component: () => import('@/plugins/app@exams/exam-results/exam-results.vue'),
            },
        ]
    },
    {
        path: '/test/:id/vysledky/:testId',
        name: 'ExamResults',
        meta: { requiresAuth: true },
        component: () => import('@/plugins/app@exams/exam-results-students/exam-results-students.vue'),
    },
    {
        path: '/test/:id/vysledky/:testId/student/:studentId',
        name: 'ExamStudentResults',
        meta: { requiresAuth: true },
        component: () => import('@/plugins/app@exams/exam-results-student-detail/exam-results-student-detail.vue'),
    },
    {
        path: '/skupina/:id?',
        name: 'GroupDetail',
        meta: { requiresAuth: true },
        component: () => import('@/plugins/app@groups/student-group/student-group.vue'),
    }

]


const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach(loginGuardIfMetaSet)

export default router
