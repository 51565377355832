import Vue from 'vue'
import App from './App.vue'
import router from '@/plugins/app/router'
import store from '@/plugins/app/_config/store'

// bootstrap, bootstrap-vue
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// app theme
import '@/plugins/app/_theme/theme.sass'

// Fullstory
// import { fullstoryInit } from '@/plugins/w@integration/fullstory'
// fullstoryInit('ZE91H')

// w/translations
import i18n from '@/plugins/w/translate'
import Translations from '@/plugins/w/translate/translations'
Translations.setup('sk', {
  sk: 'Slovak'
})

// w/toast
import wToast from '@/plugins/w/toast'
Vue.prototype.$wToast = wToast

// w@integration/analytics
// import WAnalytics from '@/plugins/w@integration/analytics'
// WAnalytics.addFacebookPixel(router)

// vee-validate
import sk from 'vee-validate/dist/locale/sk'
import VeeValidate, { Validator } from 'vee-validate'
Vue.use(VeeValidate, {
    inject: true,
    fieldsBagName: 'veeFields',
    errorBagName: 'veeErrors'
})
Validator.localize('sk', sk)

// create new vue instance
Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
